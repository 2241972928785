import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DefaultLayout from './layouts/Default';
import ErrorLayout from './layouts/Error';
import BlankLayout from './layouts/Blank/Blank';

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/financial" />
    },
    {
        path: '/erro',
        component: ErrorLayout,
        routes: [
            {
                path: '/erro/404',
                exact: true,
                component: lazy(() => import('./pages/erros/error_404')),
            },
            {
                component: () => <Redirect to="/erro/404" />,
            },
        ],
    },
    {
        path: '/auth',
        component: BlankLayout,
        routes: [
            {
                path: '/auth/login',
                exact: true,
                component: lazy(() => import('./pages/login')),
            },
            {
                component: () => <Redirect to="/erro/404" />,
            },
        ],
    },
    {
        route: '*',
        component: DefaultLayout,
        routes: [
            {
                path: '/financial',
                exact: true,
                component: lazy(() => import('./pages/financial/financial'))
            },
            {
                path: '/students',
                exact: true,
                component: lazy(() => import('./pages/students'))
            },
            {
                path: '/financial',
                exact: true,
                component: lazy(() => import('./pages/financial/financial'))
            },
            {
                path: '/units',
                exact: true,
                component: lazy(() => import('./pages/units'))
            },
            {
                path: '/classes',
                exact: true,
                component: lazy(() => import('./pages/classes'))
            },
            {
                path: '/contributors',
                exact: true,
                component: lazy(() => import('./pages/contributors'))
            },
            {
                component: () => <Redirect to="/erro/404" />,
            }
        ]
    }
];

export default routes;