import './App.css';
import { Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import routes from './routes'
import { createBrowserHistory } from 'history'
import { useEffect } from 'react';
import api from './utils/api';
import { logout } from './utils/logout';

const history = createBrowserHistory()
const url = window.location.href;

function App() {

  useEffect(() => { /** Verify logged */

    if(!url.includes('auth/login')){

      api.get('/me', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('tokenStorage')
        }
      })
      .then(res => {})
      .catch(res => {
        logout();
      })

    }
    
  }, [0]);

  return (
    <Router history={history}>
      {renderRoutes(routes)}
    </Router>
  );
}

export default App;
