import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import { LinearProgress } from '@mui/material';

const BlankLayout = props => {

  const { route } = props;

  return (
    <div className='blankLayoutRoot'>

        <Suspense fallback={<LinearProgress />}>
            <main>
                {renderRoutes(route.routes)}
            </main>
        </Suspense>
      
    </div>
  );
}

export default BlankLayout;