import React, { Suspense, useLayoutEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { LinearProgress, Paper, AppBar, Toolbar, Button, Typography, Modal, Box, Grid, TextField, IconButton, Drawer } from '@mui/material';
import ListMenu from '../../components/list-menu.js';
import logo from '../../assets/img/logo.png'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const drawerWidth = 240;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 'none',
  borderRadius: '15px'
};

const TextFieldUI = styled(TextField)({
  '& label.Mui-focused': {
      color: '#707072',
  },
  '& .MuiInput-underline:after': {},
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          border: '1px solid #D7D7D7',
          borderRadius: '12px'
      },
      '&:hover fieldset': {},
      '&.Mui-focused fieldset': {},
  }
});

const DefaultLayout = props => {

  const { route } = props;
  const [width, height] = useWindowSize();
  const location = useLocation();

  const [openModalNewMovement, setOpenModalNewMovement] = useState(false)
  const [openModalNewStudent, setOpenModalNewStudent] = useState(false)
  const [openModalNewUnit, setOpenModalNewUnit] = useState(false)
  const [openModalNewClasse, setOpenModalNewClasse] = useState(false)
  const [openModalNewContributor, setOpenModalNewContributor] = useState(false)

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div style={{ display: 'flex', overflowY: 'hidden', height: '100vh' }}>

      <Paper 
        sx={{ 
          maxHeight: '100vh', 
          height: '100vh', 
          width: drawerWidth, 
          borderRadius: '0px',
          '@media (max-width: 767px)': {
            display: 'none',
          }
        }}
      >
        <img src={logo} style={{ width: '60px', display: 'block', margin: 'auto', marginTop: '20px' }} alt="Logo" />
        <ListMenu sizeMenu={drawerWidth} />
      </Paper>

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => {setOpenDrawer(false)}}
      >
        <Box sx={{ width: '90vw' }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '28px',
            paddingLeft: '42px',
            paddingRight: '42px'
          }}>
            <Typography sx={{ fontSize: '16px' }}>Menu</Typography>
            <IconButton onClick={() => {setOpenDrawer(false)}}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <ListMenu sizeMenu={drawerWidth} />
        </Box>
      </Drawer>

      <main style={{ flexGrow: 1, overflowY: 'auto', height: '100vh', overflowY: 'scroll', backgroundColor: '#F7F7FC' }}>
        <Suspense fallback={<LinearProgress />}>

          <AppBar 
            position="relative" 
            sx={{ 
              zIndex: 1,
              boxShadow: 'none', 
              height: '75px', 
              color: '#000', 
              justifyContent: 'center', 
              position: 'fixed', 
              width: width - drawerWidth, 
              backgroundColor: '#FFF',
              '@media (max-width: 767px)': {
                width: width,
              }
            }}
          >
            <Toolbar>
              
              {/* {location.pathname === '/students' && (
                <>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Alunos</Typography>
                  <Button onClick={() => {setOpenModalNewStudent(true)}} size="large" variant="contained" style={{ textTransform: 'none', borderRadius: '8px', boxShadow: 'none' }}>Novo aluno</Button>
                </>
              )} */}

              {location.pathname === '/financial' && (
                <>
                  <IconButton 
                    sx={{
                      marginRight: '10px',
                      '@media (min-width: 768px)': {
                        display: 'none'
                      }
                    }}
                    onClick={() => {setOpenDrawer(true)}}
                  >
                    <MenuRoundedIcon />
                  </IconButton>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Financeiro</Typography>
                  <Button onClick={() => {setOpenModalNewMovement(true)}} size="large" variant="contained" style={{ textTransform: 'none', borderRadius: '8px', boxShadow: 'none' }}>Nova movimentação</Button>
                </>
              )}

              {/* {location.pathname === '/units' && (
                <>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Unidades</Typography>
                  <Button onClick={() => {setOpenModalNewUnit(true)}} size="large" variant="contained" style={{ textTransform: 'none', borderRadius: '8px', boxShadow: 'none' }}>Nova unidade</Button>
                </>
              )}

              {location.pathname === '/classes' && (
                <>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Turmas</Typography>
                  <Button onClick={() => {setOpenModalNewClasse(true)}} size="large" variant="contained" style={{ textTransform: 'none', borderRadius: '8px', boxShadow: 'none' }}>Nova turma</Button>
                </>
              )}

              {location.pathname === '/contributors' && (
                <>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Colaboradores</Typography>
                  <Button onClick={() => {setOpenModalNewContributor(true)}} size="large" variant="contained" style={{ textTransform: 'none', borderRadius: '8px', boxShadow: 'none' }}>Novo colaborador</Button>
                </>
              )} */}
              
            </Toolbar>
          </AppBar>

          <div style={{ padding: '0px 30px 10px 30px', marginTop: '80px' }}>
            {renderRoutes(route.routes, {
                handleClickNewFinancial: openModalNewMovement,
                handleCloseNewFinancial: () => { setOpenModalNewMovement(false) },
              })}
          </div>
          
        </Suspense>
      </main>

      <Modal
        open={openModalNewStudent}
        onClose={() => {setOpenModalNewStudent(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Nova estudante
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={7}>
              <TextFieldUI fullWidth label="Descrição" variant="outlined" />
            </Grid>
            <Grid item md={5}>
              <TextFieldUI 
                fullWidth 
                label="Data de nascimento" 
                variant="outlined" 
                type="date" 
                InputLabelProps={{
                  shrink: true,
                }} />
            </Grid>
          </Grid>
          <br></br>
          <Typography id="modal-modal-title" variant="body1">
            Filiação
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={6}>
              <TextFieldUI fullWidth label="Nome do pai" variant="outlined" />
            </Grid>
            <Grid item md={6}>
              <TextFieldUI fullWidth label="Nome da mãe" variant="outlined" />
            </Grid>
          </Grid>
          <br></br>
          <Typography id="modal-modal-title" variant="body1">
            Endereço
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={4}>
              <TextFieldUI fullWidth label="CEP" variant="outlined" />
            </Grid>
            <Grid item md={8}>
              <TextFieldUI fullWidth label="Lagradouro" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Bairro" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Complemento" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Cidade" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Estado" variant="outlined" />
            </Grid>
          </Grid>
          <br></br>
          <Typography id="modal-modal-title" variant="body1">
            Outras informações
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={4}>
              <TextFieldUI disabled fullWidth label="Unidade" variant="outlined" value="Franca - SP" />
            </Grid>
          </Grid>
          <br></br>
          <Button size="large" variant="contained" style={{ textTransform: 'none', borderRadius: '8px', boxShadow: 'none', float: 'right' }}>Adicionar</Button>
        </Box>
      </Modal>

      <Modal
        open={openModalNewUnit}
        onClose={() => {setOpenModalNewUnit(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Nova unidade
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={4}>
              <TextFieldUI fullWidth label="CEP" variant="outlined" />
            </Grid>
            <Grid item md={8}>
              <TextFieldUI fullWidth label="Lagradouro" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Bairro" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Complemento" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Cidade" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Estado" variant="outlined" />
            </Grid>
          </Grid>
          <br></br>
          <Button size="large" variant="contained" style={{ textTransform: 'none', borderRadius: '8px', boxShadow: 'none', float: 'right' }}>Adicionar</Button>
        </Box>
      </Modal>

      <Modal
        open={openModalNewClasse}
        onClose={() => {setOpenModalNewClasse(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Nova turma
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={6}>
              <TextFieldUI fullWidth label="Nome" variant="outlined" />
            </Grid>
            <Grid item md={6}>
              <TextFieldUI
                fullWidth
                select
                label="Professor(a)"
                SelectProps={{
                    native: true,
                }}
              >
                <option value=""></option>
                <option value="">Fulana de tal</option>
                <option value="">Fulana de tal 2</option>
              </TextFieldUI>
            </Grid>
          </Grid>
          <br></br>
          <Typography id="modal-modal-title" variant="body1">
            Outras informações
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={4}>
              <TextFieldUI disabled fullWidth label="Unidade" variant="outlined" value="Franca - SP" />
            </Grid>
          </Grid>
          <br></br>
          <Button size="large" variant="contained" style={{ textTransform: 'none', borderRadius: '8px', boxShadow: 'none', float: 'right' }}>Criar</Button>
        </Box>
      </Modal>

      <Modal
        open={openModalNewContributor}
        onClose={() => {setOpenModalNewContributor(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Novo(a) colaborador(a)
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={7}>
              <TextFieldUI fullWidth label="Nome" variant="outlined" />
            </Grid>
            <Grid item md={5}>
              <TextFieldUI
                fullWidth
                select
                label="Cargo"
                SelectProps={{
                    native: true,
                }}
              >
                <option value=""></option>
                <option value="">Professor(a)</option>
                <option value="">Estagiário(a)</option>
              </TextFieldUI>
            </Grid>
            <Grid item md={4}>
              <TextFieldUI fullWidth label="Salário" variant="outlined" />
            </Grid>
            <Grid item md={4}>
              <TextFieldUI disabled fullWidth label="Status" variant="outlined" value="Ativo" />
            </Grid>
          </Grid>
          <br></br>
          <Typography id="modal-modal-title" variant="body1">
            Endereço
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={4}>
              <TextFieldUI fullWidth label="CEP" variant="outlined" />
            </Grid>
            <Grid item md={8}>
              <TextFieldUI fullWidth label="Lagradouro" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Bairro" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Complemento" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Cidade" variant="outlined" />
            </Grid>
            <Grid item md={3}>
              <TextFieldUI fullWidth label="Estado" variant="outlined" />
            </Grid>
          </Grid>
          <br></br>
          <Typography id="modal-modal-title" variant="body1">
            Outras informações
          </Typography>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={4}>
              <TextFieldUI disabled fullWidth label="Unidade" variant="outlined" value="Franca - SP" />
            </Grid>
          </Grid>
          <br></br>
          <Button size="large" variant="contained" style={{ textTransform: 'none', borderRadius: '8px', boxShadow: 'none', float: 'right' }}>Criar</Button>
        </Box>
      </Modal>
      
    </div>
  );
}

export default DefaultLayout;