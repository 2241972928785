import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import { LinearProgress } from '@mui/material';

const DefaultLayout = props => {

  const { route } = props;

  return (
    <div className='errorLayoutRoot'>

        <Suspense fallback={<LinearProgress />}>
            <main>
                {renderRoutes(route.routes)}
            </main>
        </Suspense>
      
    </div>
  );
}

export default DefaultLayout;