import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';

const ListMenu = props => {

  const location = useLocation();

  const handleLogout = () => {

    localStorage.clear()
    if(window.location.hostname == 'localhost'){
      window.location.href = 'http://localhost:3000';
    }else{
      window.location.href = 'https://appandwebstudio.com.br';
    }

  }

  return (
    <div style={{ display: 'flex', paddingTop: '10px' }}>
      <List style={{width: '100%'}}>
        {/* <Link to="/" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Dashboard" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <DashboardRoundedIcon style={{color: location.pathname === '/dashboard' || location.pathname === '/' ? '#1876D2' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Dashboard" style={{color: location.pathname === '/dashboard' || location.pathname === '/'  ? '#1876D2' : 'gray'}} />
          </ListItem>
        </Link> */}
        <Link to="/financial" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Financeiro" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <AttachMoneyRoundedIcon style={{color: location.pathname === '/financial' ? '#1876D2' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Financeiro" style={{color: location.pathname === '/financial' ? '#1876D2' : 'gray'}} />
          </ListItem>
        </Link>
        {/* <Link to="/students" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Alunos" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <PeopleOutlineRoundedIcon style={{color: location.pathname === '/students' ? '#1876D2' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Alunos" style={{color: location.pathname === '/students' ? '#1876D2' : 'gray'}} />
          </ListItem>
        </Link>
        <Link to="/units" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Unidades" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <CorporateFareRoundedIcon style={{color: location.pathname === '/units' ? '#1876D2' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Unidades" style={{color: location.pathname === '/units' ? '#1876D2' : 'gray'}} />
          </ListItem>
        </Link>
        <Link to="/classes" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Turmas" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <SchoolRoundedIcon style={{color: location.pathname === '/classes' ? '#1876D2' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Turmas" style={{color: location.pathname === '/classes' ? '#1876D2' : 'gray'}} />
          </ListItem>
        </Link>
        <Link to="/contributors" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Colaboradores" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <PeopleOutlineRoundedIcon style={{color: location.pathname === '/contributors' ? '#1876D2' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Colaboradores" style={{color: location.pathname === '/contributors' ? '#1876D2' : 'gray'}} />
          </ListItem>
        </Link> */}
        <ListItem onClick={handleLogout} button>
            <Tooltip title="Sair" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <ExitToAppIcon style={{color: 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Sair" style={{color: 'gray'}} />
          </ListItem>
      </List>
    </div>
  );
}

export default ListMenu;